<template>
<svg width="30px" height="28px" viewBox="0 0 38 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>envelope</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="envelope" fill="#000000" fill-rule="nonzero">
            <path d="M1.2803238,2.25714237 C6.49936746,6.56850453 15.6571663,14.1535056 18.3487578,16.5185207 C18.7100877,16.8377199 19.0976353,17 19.4999629,17 C19.9014735,17 20.2883527,16.8392413 20.6488655,16.5215635 C23.3427595,14.15423 32.5005583,6.56850453 37.7196762,2.25714237 C38.0446131,1.98923531 38.094152,1.51833322 37.8310831,1.1908027 C37.2229498,0.433954384 36.3162459,0 35.3444803,0 L3.65544542,0 C2.68375412,0 1.77697592,0.433954384 1.16891686,1.19087515 C0.905847945,1.51833322 0.955386897,1.98923531 1.2803238,2.25714237 Z" id="Path"></path>
            <path d="M37.5408086,5.0711227 C37.2601875,4.94524752 36.9300625,4.9891608 36.6965703,5.18140651 C30.9128516,9.90615961 23.5312031,15.9623287 21.20875,17.9789776 C19.9052461,19.1128555 18.0961641,19.1128555 16.7896172,17.9774757 C14.314125,15.8281572 6.02478125,9.03747789 1.30342969,5.18133499 C1.06837891,4.98908928 0.737511719,4.94667792 0.459191407,5.07105118 C0.179386719,5.19628266 0,5.46598452 0,5.76322156 L0,24.9484569 C0,26.6314651 1.42017578,28 3.16669141,28 L34.8333828,28 C36.5798242,28 38,26.6314651 38,24.9484569 L38,5.76322156 C38,5.46598452 37.8206133,5.19556747 37.5408086,5.0711227 Z" id="Path"></path>
        </g>
    </g>
</svg>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>