<template>
<svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>external-link-alt-solid</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="external-link-alt-solid" fill="#000000" fill-rule="nonzero">
            <path d="M23.625,17.5 L21.875,17.5 C21.3917508,17.5 21,17.8917508 21,18.375 L21,24.5 L3.5,24.5 L3.5,7 L11.375,7 C11.8582492,7 12.25,6.60824916 12.25,6.125 L12.25,4.375 C12.25,3.89175084 11.8582492,3.5 11.375,3.5 L2.625,3.5 C1.17525253,3.5 0,4.67525253 0,6.125 L0,25.375 C0,26.8247475 1.17525253,28 2.625,28 L21.875,28 C23.3247475,28 24.5,26.8247475 24.5,25.375 L24.5,18.375 C24.5,17.8917508 24.1082492,17.5 23.625,17.5 Z M26.6875,0 L19.6875,0 C18.5188281,0 17.9347656,1.41695312 18.7578125,2.2421875 L20.7117969,4.19617187 L7.3828125,17.5202344 C7.13568469,17.7665056 6.9967775,18.1010356 6.9967775,18.4499219 C6.9967775,18.7988082 7.13568469,19.1333382 7.3828125,19.3796094 L8.62257812,20.6171875 C8.86884933,20.8643153 9.20337931,21.0032225 9.55226562,21.0032225 C9.90115194,21.0032225 10.2356819,20.8643153 10.4819531,20.6171875 L23.804375,7.2909375 L25.7578125,9.2421875 C26.578125,10.0625 28,9.48828125 28,8.3125 L28,1.3125 C28,0.587626266 27.4123737,0 26.6875,0 Z" id="Shape"></path>
        </g>
    </g>
</svg>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>