<template>
  <div>
    <b-container fluid v-if="currentDisplayAd">
      <!---- CUSTOMIZATION ---->
      <b-row class="justify-content-center align-items-center box-shadow-top" :style="{
        'background-color': primaryColor,
      }">
        <b-img alt="match-icon" class="logo my-0 py-2" v-if="(publicCustomization &&
            publicCustomization.logo.contentUrl &&
            publicCustomization.enabled) ||
          isEmailPreview
          " :src="publicCustomization.logo.contentUrl"></b-img>
        <b-img v-else alt="match-icon" class="logo my-0 py-2" :src="require('@/assets/logo/melo-white.svg')"></b-img>
      </b-row>
      <!---- PHOTOS ---->
      <b-row class="pt-md-5">
        <b-col md="10" class="mx-auto d-flex flex-column overflow-x-hidden">
          <CoolLightBox :items="currentDisplayAd.pictures" :index="imgIndex" v-if="adHasPictures"
            @close="imgIndex = null"></CoolLightBox>
          <agile :options="carouselOptions" v-if="adHasPictures" class="thumbnails" ref="thumbnails">
            <div class="slide" v-for="(slide, index) in currentDisplayAd.pictures" :key="index">
              <img :src="slide" alt="img-slide" class="slide-img" @click="imgIndex = index" />
            </div>
            <template slot="prevButton">
              <b-img class="chevron" alt="chevron" :src="require('@/assets/icons/chevron-left.svg')"></b-img>
            </template>
            <template slot="nextButton">
              <b-img alt="chevron" class="chevron" :src="require('@/assets/icons/chevron-right.svg')"></b-img>
            </template>
          </agile>
          <b-row v-else class="justify-content-center align-items-start my-3">
            <b-col md="8">
              <b-img alt="slide" class="placeholder-img"
                :src="require('@/assets/img/placeholder-image-banner.svg')"></b-img>
            </b-col>
          </b-row>

          <b-row class="justify-content-center align-items-start mt-md-2 pb-10">
            <b-col md="8" class="text-left position-relative">
              <!---- ACTIONS MD ---->
              <div class="d-none d-md-flex flex-md-column position-absolute">
                <b-button class="py-3 px-4" v-b-tooltip.hover title="En savoir plus" :href="getUrl" v-if="getUrl"
                  target="_blank" rel="noreferrer">
                  <IconLink />
                </b-button>

                <b-button v-b-tooltip.hover title="Partager par Email" class="py-3 px-4" target="_blank" rel="noopener"
                  :href="emailUrl">
                  <IconEnvelope />
                </b-button>

                <b-button v-b-tooltip.hover title="Partager par WhatsApp" class="py-3 px-4" target="_blank" rel="noopener"
                  :href="whatsAppUrl">
                  <IconWhatsapp />
                </b-button>
              </div>
              <!---- AD INFORMATION ---->
              <b-card class="card">
                <div class="d-md-flex align-items-baseline">
                  <div class="price" v-if="currentDisplayAd.price">
                    {{
                      currentDisplayAd.price
                      | number("0,0", { thousandsSeparator: " " })
                    }}
                    €
                  </div>
                  <div class="price-per-meter" v-if="currentDisplayAd.pricePerMeter">
                    {{
                      currentDisplayAd.pricePerMeter
                      | number("0,0", { thousandsSeparator: " " })
                    }}
                    € / m²
                  </div>
                </div>
                <div class="d-flex">
                  <div class="city" v-if="currentDisplayAd.city.groupedCityNames.length < 3">
                    {{ currentDisplayAd.city.name | capitalize }}
                    <span class="zipcode ml-md-2 d-block d-md-inline" v-if="currentDisplayAd.city.zipcode">
                      {{ currentDisplayAd.city.zipcode }}
                    </span>
                  </div>
                  <div v-b-tooltip.hover.left
                    :title="`Villes correspondant à ce code postal : ${currentDisplayAd.city.name}`" v-else class="city">
                    {{ currentDisplayAd.city.zipcode }}
                  </div>
                </div>
                <ul class="px-0 my-3 align-items-center">
                  <li class="main-feature" v-if="currentDisplayAd.surface" :style="{
                    'background-color': primaryColor,
                  }">
                    {{ currentDisplayAd.surface.toFixed() }} m²
                  </li>
                  <li class="main-feature" v-if="currentDisplayAd.bedroom === 0" :style="{
                    'background-color': primaryColor,
                  }">
                    Studio
                  </li>
                  <li class="main-feature" v-if="currentDisplayAd.bedroom > 0" :style="{
                    'background-color': primaryColor,
                  }">
                    {{ currentDisplayAd.bedroom }}
                    {{ currentDisplayAd.bedroom | pluralize("chambre") }}
                  </li>
                  <li class="main-feature" v-if="currentDisplayAd.floor === 0" :style="{
                    'background-color': primaryColor,
                  }">
                    Rez-de-chaussée
                  </li>
                  <li class="main-feature" v-if="currentDisplayAd.floor === 1" :style="{
                    'background-color': primaryColor,
                  }">
                    {{ currentDisplayAd.floor }}<sup>er</sup>
                    étage
                  </li>
                  <li class="main-feature" v-if="currentDisplayAd.floor > 1" :style="{
                    'background-color': primaryColor,
                  }">
                    {{ currentDisplayAd.floor }}<sup>e</sup>
                    étage
                  </li>
                  <li class="main-feature" v-if="currentDisplayAd.room > 0" :style="{
                    'background-color': primaryColor,
                  }">
                    {{ currentDisplayAd.room }}
                    {{ currentDisplayAd.room | pluralize("pièce") }}
                  </li>
                </ul>
                <hr />
                <span v-if="currentDisplayAd.title">
                  {{ currentDisplayAd.title }}
                </span>
                -
                <span class="description mb-2" v-if="currentDisplayAd.description">
                  {{ currentDisplayAd.description }}
                </span>
                <ul v-if="currentDisplayAd.features" class="list-inline mt-3">
                  <li v-for="(feature, index) in currentDisplayAd.features" :key="index" class="list-inline-item feature">
                    {{ feature }}
                  </li>
                </ul>
                <hr />
                <div class="d-flex justify-content-between align-items-center">
                  <div class="ref opacity-low" v-if="currentDisplayAd.uuid">
                    Ref : {{ currentDisplayAd.uuid }}
                  </div>
                  <div class="published-time d-flex align-items-center">
                    <b-img class="clock-icon" :src="require('@/assets/icons/clock.svg')"></b-img>
                    <div class="opacity-low">
                      {{ currentDisplayAd.updatedAt | moment("from") }}
                    </div>
                  </div>
                </div>
                <hr />
                <div v-if="cleansedContacts" class="d-md-flex align-items-stretch">
                <ul class="contact-card mr-md-3 mb-md-0" v-for="(contact, index) of cleansedContacts" :key="index">
                  <li v-if="contact.publisherType == 0">
                    Particulier
                  </li>
                  <li class="agency-name" v-if="contact.agency">
                    {{ contact.agency | capitalize }}
                  </li>
                  <li class="contact-name" v-if="contact.name">
                    {{ contact.name | capitalize }}
                  </li>

                  <b-link class="contact-phone d-block" v-bind:href="'tel:' + contact.phone">
                    {{ contact.phone }}
                  </b-link>
                  <b-link class="contact-email d-block" v-bind:href="'mailto:' + contact.email"
                    v-if="contact.email != null">
                    {{ contact.email }}
                  </b-link>
                </ul>
              </div>
              </b-card>
            </b-col>          
            <!--TIMELINE-->
            <b-col md="8" class="mx-auto" v-if="cleansedEvents">
              <b-card>
                <VueTimelinePro :updates="cleansedEvents" :formatDate="formatDate" />
              </b-card>
            </b-col>
            <!---- MAP ---->
            <b-col md="8" class="mx-auto" v-if="currentDisplayAd.location && currentDisplayAd.location.lat > 0
              ">
              <div class="osmap">
                <l-map :zoom="zoom" :center="[
                  currentDisplayAd.location.lat,
                  currentDisplayAd.location.lon,
                ]" class="box-shadow border-radius" style="height: 500px">
                  <l-tile-layer :url="url"></l-tile-layer>
                  <l-marker :lat-lng="[
                    currentDisplayAd.location.lat,
                    currentDisplayAd.location.lon,
                  ]"></l-marker>
                </l-map>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!---- ACTIONS XS ---->
      <div class="
          d-flex d-md-none
          row-fluid
          flex-row
          h-2
          fixed-bottom
          align-items-center
          text-bold
          box-shadow-bottom
          py-2
        " :style="{
          'background-color': primaryColor,
        }">
        <b-link v-if="getUrl" class="flex-fill text-white" target="_blank" rel="noreferrer"
          :class="{ 'border-right': webShareApiSupported }" :href="getUrl">
          <IconLink class="fill-white" />
        </b-link>
        <div v-if="webShareApiSupported" @click="shareViaWebShare" class="flex-fill">
          <IconShare class="fill-white" />
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { VueAgile } from "vue-agile";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import { mapState, mapGetters } from "vuex";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import CoolLightBox from "vue-cool-lightbox";
import IconWhatsapp from "@/assets/icons/IconWhatsapp.vue";
import IconShare from "@/assets/icons/IconShare.vue";
import IconEnvelope from "@/assets/icons/IconEnvelope.vue";
import IconLink from "@/assets/icons/IconLink.vue";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("../assets/icons/marker-map.svg"),
  iconUrl: require("../assets/icons/marker-map.svg"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: {
    agile: VueAgile,
    CoolLightBox,
    IconWhatsapp,
    IconShare,
    LMap,
    LTileLayer,
    LMarker,
    IconLink,
    IconEnvelope,
  },
  data() {
    return {
      imgIndex: null,
      carouselOptions: {
        navButtons: true,
        dots: false,
        centerMode: true,
        slidesToShow: 1,
        timing: "ease",
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
            },
          },
        ],
      },
      shareIcons: false,
      zoom: 13,
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
    };
  },
  computed: {
    ...mapState(["currentDisplayAd", "publicCustomization"]),
    ...mapGetters([
      "cleansedContacts",
      "cleansedEvents",
      "formatDate",
      "getUrl",
    ]),
    adHasPictures() {
      return (
        this.$store.state.currentDisplayAd &&
        this.$store.state.currentDisplayAd.pictures.length != 0
      );
    },
    isPublicCustomizationEnabled() {
      return (
        this.$store.state.publicCustomization &&
        this.$store.state.publicCustomization.enabled
      );
    },
    isStandardPlan() {
      return (
        this.currentDisplayAd.user.plan == "melo_standard_multi_search_plan"
      );
    },
    isEmailPreview() {
      return (
        Object.prototype.hasOwnProperty.call(this.$route.query, "preview") &&
        this.$route.query.preview == 1
      );
    },
    webShareApiSupported() {
      return navigator.share;
    },
    isAdLoading() {
      return this.$store.state.currentDisplayAd == null;
    },
    primaryColor() {
      return this.isPublicCustomizationEnabled || this.isEmailPreview
        ? this.$store.state.publicCustomization.primaryColor
        : "#3d5afe";
    },
    fullPath() {
      return this.$router.currentRoute.fullPath;
    },
    whatsAppUrl() {
      return (
        "https://api.whatsapp.com/send?text=https://match.notif.immo" +
        encodeURIComponent(this.fullPath)
      );
    },
    emailUrl() {
      return (
        "mailto:?subject=Annonce%20immobili%C3%A8re&body=" +
        this.currentDisplayAd.city.name +
        " - " +
        this.currentDisplayAd.surface.toFixed() +
        " m²" +
        " - " +
        this.currentDisplayAd.price +
        " €" +
        "%0D%0Ahttps://match.notif.immo" +
        encodeURIComponent(this.fullPath)
      );
    },
  },
  methods: {
    fetchAdDisplay() {
      let payload = {
        token: this.$route.params.token,
        customization: this.$route.query.customization
          ? this.$route.query.customization
          : null,
      };
      if (
        Object.prototype.hasOwnProperty.call(this.$route.query, "customization")
      ) {
        this.$store
          .dispatch("fetchPublicCustomization", this.$route.query.customization)
          .then(() => {
            this.$store.dispatch("fetchAdDisplay", payload);
          });
      } else {
        this.$store.dispatch("fetchAdDisplay", payload);
      }
    },

    showShareIcons() {
      this.shareIcons = true;
    },
    shareViaWebShare() {
      navigator.share({
        title: "Annonce immobilière",
        text:
          this.currentDisplayAd.city.name +
          " - " +
          this.currentDisplayAd.surface.toFixed() +
          " m²" +
          " - " +
          this.currentDisplayAd.price +
          " €",
        url: this.$route.fullPath,
      });
    },
  },
  mounted() {
    this.fetchAdDisplay();
    //console.log(this.fullPath)
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "@/styles/theme.scss";
@import "@/styles/_variables.scss";

.btn {
  color: inherit;
  border: none;
  background-color: white;
  box-shadow: $box-shadow;
}

.contact-card {
  list-style: none;
  padding: 0;
  word-break: break-word;
  font-size: inherit;
  background-color: #E1EFFE;
  color: #1E429F;
  padding: 0.8rem;
  border-radius: $radius;
}

::v-deep {
  .btn {
    svg {
      path {
        fill: #bababa !important;
      }
    }
  }

  .timeline_pro_update_right {
    text-align: left;
    font-family: "Rethink Sans", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .timeline_pro_update_center_line {
    margin-top: 60px;
    border: 2px solid #f0f0f0;
    height: calc(100% - 45px);
  }

  .timeline_pro_update_right_head_title {
    color: black;

    &:hover {
      text-decoration: none;
    }
  }

  .timeline_pro_update_right_content {
    font-size: 1rem;
  }

  .timeline_pro_update_right_head_date {
    opacity: 0.3;
    font-size: 0.9rem;
  }

  .timeline_pro_update {
    padding-bottom: 0;
  }

  .timeline_pro_update_center {
    margin-left: 20px;
  }
}

.position-absolute {
  bottom: 1rem;
  left: 1rem;

  @include media-breakpoint-up(md) {
    top: 0;
    left: -5rem;

    .btn {
      margin-bottom: 0.4rem;
    }
  }
}

.price {
  margin-right: 1rem;
  font-size: 2.5rem;
  font-weight: bold;
}

.price-per-meter {
  font-size: 1.2rem;
  opacity: 0.5;
}

.zipcode {
  font-size: 1.2rem;
  opacity: 0.5;
  font-weight: normal;
}

.city {
  font-size: 2rem;
  font-weight: bold;
}

.description {
  text-align: justify;
}

.card {
  max-width: 100%;
  text-align: left;
  border: none;
  box-shadow: $box-shadow;
  margin-bottom: 1rem;
}

.placeholder-img {
  border-radius: 5px;
  box-shadow: $box-shadow;
  width: 100%;
}

.main-feature {
  color: white;
  font-weight: bold;
}

.feature {
  background-color: $bg-grey;
}

.contact-type {
  background: black;
  color: white;
  font-size: 1.2rem;
}

.feature,
.main-feature,
.contact-type {
  padding: 0.3rem 0.7rem 0.3rem 0.7rem;
  border-radius: $radius;
  margin-bottom: 0.4rem;
  margin-right: 0.4rem;
  display: inline-block;
  white-space: nowrap;
}

.agency-name,
.contact-name,
.contact-type {
  font-weight: bold;
}

.contact-email,
.contact-phone,
.contact-name,
.agency-name {
  font-size: 1.2rem;
  color: black;
}

.contact-icon {
  height: 1.2rem;
  width: 1.2rem;
  margin-right: 0.5rem;
  display: block;
}
</style>

<style lang="scss">
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "@/styles/_variables.scss";
@import "@/styles/theme.scss";

.thumbnails {
  margin: 0 -5px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.slide {
  cursor: pointer;
  height: 450px;
  padding: 0 5px;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.slide img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  width: 100%;
  border-radius: $radius;
  box-shadow: $box-shadow;
}

.published-time {
  .clock-icon {
    width: 1.5rem;
    margin-right: 0.1rem;
  }
}

.agile__list {
  padding-bottom: 1rem;
}

.agile__nav-button {
  background: hsla(0, 0%, 100%, 0.5);
  border: none;
  color: #fff;
  cursor: pointer;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  transition-duration: 0.3s;
  padding: 1.5rem;
}

.chevron {
  width: 1.5rem;
  opacity: 0.5;
}

.agile__nav-button--prev {
  left: -20px;
  border-radius: 0 80px 80px 0;
}

.agile__nav-button--next {
  right: -20px;
  border-radius: 80px 0 0 80px;
}

.bg-placeholder {
  background-color: #fafafafa;
}
</style>
